import * as React from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';
import { Layout, Logo, Map, SEO } from '../components';

const fullConfig = resolveConfig(tailwindConfig);

export default function COVIDMessagePage() {
  return (
    <Layout>
      <SEO title="Clinic Entry Declaration" />
      <Message />
      <Map />
    </Layout>
  );
}

function Message() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="py-24 mx-auto prose text-gray-600">
        <h1
          style={{
            color: fullConfig.theme.colors.gray[700],
            lineHeight: fullConfig.theme.lineHeight.tight,
          }}
          className="font-serif font-semibold leading-tight uppercase"
        >
          Clinic Entry Declaration
        </h1>
        <p>
          We are a Safe Clinic. All of our team members have completed
          educational modules on hygienic and safe treatment practices.
        </p>
        <p>
          New policies and high standards have been implemented to maintain a
          clean and safe environment, protecting you and our team.
        </p>
        <p>
          By entering this clinic, you agree to have your temperature taken and
          personal details recorded, as required by the Government for tracing.
        </p>
        <p>
          This clinic has a capacity of 10, including team members (in
          compliance with the 4m² rule). While social distancing isn’t always
          possible during treatments, our team is trying their best to keep
          distance and we ask that you do the same. When social distancing is
          not possible our team use PPE.
        </p>
        <p>
          We appreciate your patience while we take extra time following a
          rigorous sanitisation process, ensuring all treatment rooms,
          equipment, products and high touch surfaces are sanitised regularly
          for your safety.
        </p>
        <p>
          Upon entry, please sanitise your hands and avoid touching objects and
          surfaces unnecessarily.
        </p>
        <p>
          Contactless payment or prepayment, for treatment and products, is
          preferred.
        </p>
        <p>
          If you or someone you know displays any flu like or COVID-19 symptoms,
          please cancel your appointment.
        </p>
        <p>Thank you for supporting and trusting us to look after you.</p>
        <br />
        <p className="italic">Jamie &amp; the Port Day Spa team xo</p>
        <Logo className="h-10 mx-auto mt-8 fill-current" />
        <p className="italic">
          <small className="leading-none">
            This clinic will do everything possible to minimise the spread of
            COVID-19, but can not be held responsible if a client contracts
            COVID-19.
          </small>
        </p>
      </div>
    </div>
  );
}
